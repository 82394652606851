import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '../Loading';

import { useAppConfig } from '@domui-hooks/appConfig';

type Props = {
  Component: React.ElementType;
  [key: string]: unknown;
};

const AuthenticatedRoute: React.FC<RouteComponentProps & Props> = ({
  Component,
  ...props
}) => {
  const {
    user = { individualId: '', isLoggedIn: false },
    login,
  } = useAppConfig();

  useEffect(() => {
    if (!user?.isLoggedIn) {
      login(true);
    }
  }, [user?.isLoggedIn]);

  if (!user?.isLoggedIn) {
    return <Loading />;
  }

  return <Component {...props} />;
};

export default AuthenticatedRoute;
