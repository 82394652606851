import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Metadata from '@domui-utils/workflow/metadata';

// Custom hook to handle API calls
export const useFetchCountries = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(null);

  const fetchCountries = async () => {
    // Clear error before new request
    try {
      setLoading(true);
      setError(null);
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setData(response?.countries);
      setLoading(false);
    } catch (err) {
      setError(err as DynamicData);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchCountries };
};
