/* eslint-disable @typescript-eslint/no-explicit-any */

import Workflow from './base';

import { DomuiError, DynamicData } from '@domui-domain/type';

class Organization extends Workflow {
  constructor() {
    super('organizationwf');
  }

  async getDominoOrganizationId(disId: string) {
    this.setAction('getOrganizationId');
    this.setData({
      idType: 'DISID',
      value: disId,
    });
    try {
      const res: any = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch organization id');
    }
  }

  async getOrganization(dominoId: string) {
    this.setAction('getOrganization');
    this.setData({
      id: dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch get organization.');
    }
  }

  async addMeetingDetails(orgId: string, data: DynamicData) {
    this.setAction('addMeetingDetails');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateClubContactInfo(orgId: string, data: DynamicData) {
    this.setAction('updateClubContactInfo');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateMeeting(orgId: string, data: DynamicData) {
    this.setAction('updateMeeting');
    this.setData({
      id: orgId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default Organization;
