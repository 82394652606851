import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { FEATURE_DOMINO_CDM_PI1, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';

import {
  mapMeetingInputToValues,
  mapMeetingValuesToFormInput,
} from '@domui-domain/clubs';
import MeetingForm from '@domui-presenters/web/components/ClubsMeetingForm';
import { MeetingFormValues } from '@domui-presenters/web/components/ClubsMeetingForm/types';
import { getBackButtonLabel, getClubDetailsPath } from '@domui-use-cases/clubs';
import { useFetchCountries } from 'src/domui/hooks/useFetchCountries';
import { useFetchOrganization } from 'src/domui/hooks/useFetchOrganization';
import { useUpdateMeeting } from 'src/domui/hooks/useUpdateMeeting';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
  meetingIndex?: string;
}
const EditMeeting: React.FC<Props> = ({ clubId, meetingIndex }) => {
  const { t, i18n } = useTranslation();
  const { addError } = useNotifications();
  const [meetingInfo, setMeetingInfo] = useState(null);

  // const { data, loading, error } = useFetchClubMeeting(clubId, dominoId);
  // const [updateMeeting, { loading: isUpdating }] = useUpdateClubMeeting();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const isDominoFeature = isEnabled(FEATURE_DOMINO_CDM_PI1);

  // const [editMeeting, { loading }] = useEditMeeting();
  // if (
  //   loading ||
  //   isUpdating ||
  //   error ||
  //   !data?.clubById?.meetings ||
  //   !meetingIndex ||
  //   isMeetingEditing
  // ) {
  //   return <Loading />;
  // }

  const clubDetailsPath = getClubDetailsPath(clubId);

  const {
    data: getOrganizationData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: getOrganizationLoading,
    // error: getOrganizationError,
    fetchOrganization,
  } = useFetchOrganization();

  useEffect(() => {
    if (clubId) {
      fetchOrganization(clubId);
    }
  }, [clubId]);

  const {
    data: countries,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: countriesLoading,
    // error: getOrganizationError,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: updatedMeetingData,
    error: updatedMeetingError,
    updateMeeting,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  } = useUpdateMeeting();

  useErrorHandling(
    updatedMeetingError?.description
      ? updatedMeetingError.description
      : t('add-meeting.error', 'An error occurred.'),
    !!updatedMeetingError,
    'add-meeting.error'
  );

  useEffect(() => {
    if (meetingInfo) {
      updateMeeting(clubId, meetingInfo, clubDetailsPath);
    }
  }, [meetingInfo]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { meetings }: any = getOrganizationData || {};

  const meeting = meetings?.[Number(meetingIndex)];

  // const { countries } = data;

  const handleSubmit = async (values: MeetingFormValues) => {
    try {
      if (isDominoFeature) {
        // const mutValue = {
        //   clubId,
        //   dominoId,
        //   meetingId: meeting.meetingId as string,
        //   meeting: { ...mapMeetingInputToValues(i18n.language, values) },
        // };
        // await editMeeting({ variables: mutValue });
      } else {
        // const mutValues = {
        //   meetings: [
        //     ...meetings.slice(0, Number(meetingIndex)).map(mapMeetingValues),
        //     {
        //       ...mapMeetingInputToValues(i18n.language, values),
        //       meetingId: null,
        //     },
        //     ...meetings.slice(Number(meetingIndex) + 1).map(mapMeetingValues),
        //   ],
        //   clubId,
        // };
        // await updateMeeting({ variables: mutValues });
        const countryValue = countries?.find(
          (c: { countryName: string }) => c.countryName === values.countryId
        );
        // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
        const mutValues: any = {
          meetingId: meeting.meetingId,
          meeting:
            //  ...meetings.map(mapMeetingValues),
            {
              ...mapMeetingInputToValues(i18n.language, countryValue, values),
            },
        };
        setMeetingInfo(mutValues);
      }
    } catch (error) {
      addError((error as Error).message);
    }
  };

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  return (
    <div className="mt-4">
      {meeting && (
        <>
          <LinkPrevious
            path=""
            showModal={modalBackHandler}
            label={getBackButtonLabel(t)}
          />
          <Title>{t('club-editmeeting.title', 'Edit Meeting')}</Title>
          <MeetingForm
            initialValues={mapMeetingValuesToFormInput(meeting)}
            countries={countries}
            handleSubmit={handleSubmit}
            onCancelClick={modalBackHandler}
          />
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      )}
    </div>
  );
};

export default EditMeeting;
