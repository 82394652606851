/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import Link from '@components/Link';
import Loading from '@components/Loading';

import { FEATURE_DOMINO_CDM_PI1, isEnabled } from '@utils/features';
// import { getClubTypeName } from '@utils/getClubTypeName';

import { useTranslation } from '@external/react-i18next';

export type Maybe<T> = T | null;

enum ClubStatus {
  Active = 'Active',
  Suspended = 'Suspended',
  Terminated = 'Terminated',
}

type ClubRelationship = {
  startDate?: string;
  endDate?: string;
  id?: string;
  childClubId?: string;
  childDisId?: string;
  parentClubId?: string;
  parentDisId?: string;
  nfKey?: string;
  type?: string;
  clubName?: string;
  status?: string;
};

interface ClubRelationshipWithStatus extends ClubRelationship {
  status: ClubStatus;
}
interface ClubRelationships {
  active: ClubRelationshipWithStatus[];
  terminated: ClubRelationshipWithStatus[];
}
interface Props {
  childClubRelationships?: Array<ClubRelationship> | null;
  parentClubRelationships?: Array<ClubRelationship> | null;
  isRotaractClubType: boolean | undefined;
}
const Sponsorship: React.FC<Props> = ({
  childClubRelationships = null,
  parentClubRelationships = null,
  isRotaractClubType,
}) => {
  const { t } = useTranslation();
  const isDominoFeature = isEnabled(FEATURE_DOMINO_CDM_PI1);
  const getClubNameAndType = (clubName?: string, type?: string) =>
    type && `${clubName || ''} (${type || ''})`;

  const PARENT_BLOCK_TYPE = 'parent';
  const CHILD_BLOCK_TYPE = 'child';
  const STATUS_ACTIVE_KEY = 'active';
  const STATUS_TERMINATED_KEY = 'terminated';

  const [parentRelationships, setParentRelationships] = useState<
    ClubRelationships
  >();

  const [childRelationships, setChildRelationships] = useState<
    ClubRelationships
  >();

  const setParentStatusInClubRelationship = (
    clubRelationships: ClubRelationship[] | null,
    clubStatusRecord: Record<string, ClubStatus>
  ) =>
    clubRelationships?.map(club => ({
      ...club,
      status: clubStatusRecord[club.parentClubId || ''],
    }));

  const setChildStatusInClubRelationship = (
    clubRelationships: ClubRelationship[] | null,
    clubStatusRecord: Record<string, ClubStatus>
  ) =>
    clubRelationships?.map(club => ({
      ...club,
      status: clubStatusRecord[club.childClubId || ''],
    }));

  const filterClubRelationships = (
    statusKey: string,
    clubRelationshipWithStatus?: ClubRelationshipWithStatus[]
  ) => {
    const isActiveOrSuspendedFilter = (club: ClubRelationshipWithStatus) =>
      statusKey === STATUS_ACTIVE_KEY &&
      (club.status === ClubStatus.Active ||
        club.status === ClubStatus.Suspended);
    const isTerminatedFilter = (club: ClubRelationshipWithStatus) =>
      statusKey === STATUS_TERMINATED_KEY &&
      club.status === ClubStatus.Terminated;
    return (
      clubRelationshipWithStatus?.filter(
        club =>
          (!club.endDate ||
            (club.endDate && moment(club.endDate) > moment(new Date()))) &&
          (isActiveOrSuspendedFilter(club) || isTerminatedFilter(club))
      ) ?? []
    );
  };

  const setClubRelationships = (
    childClubRelationships: ClubRelationship[] | null,
    parentClubRelationships: ClubRelationship[] | null,
    clubStatusRecord: Record<string, ClubStatus>
  ) => {
    const parentClubRelationshipsWithStatus = setParentStatusInClubRelationship(
      parentClubRelationships,
      clubStatusRecord
    );
    const childClubRelationshipsWithStatus = setChildStatusInClubRelationship(
      childClubRelationships,
      clubStatusRecord
    );
    setParentRelationships({
      active: filterClubRelationships(
        STATUS_ACTIVE_KEY,
        parentClubRelationshipsWithStatus
      ),
      terminated: filterClubRelationships(
        STATUS_TERMINATED_KEY,
        parentClubRelationshipsWithStatus
      ),
    });
    setChildRelationships({
      active: filterClubRelationships(
        STATUS_ACTIVE_KEY,
        childClubRelationshipsWithStatus
      ),
      terminated: filterClubRelationships(
        STATUS_TERMINATED_KEY,
        childClubRelationshipsWithStatus
      ),
    });
  };

  function getClubIdsAndStatus(
    parentClubRelationships: any,
    childClubRelationships: any
  ) {
    const parentClubRelationshipsValues = parentClubRelationships?.reduce(
      (clubIds: string[], club: any) => {
        clubIds[club?.parentClubId] = club.status;
        return clubIds;
      },
      []
    );

    const clubIdsAndStatusValues = childClubRelationships?.reduce(
      (clubIds: string[], club: any) => {
        clubIds[club?.childClubId] = club.status;
        return clubIds;
      },
      parentClubRelationshipsValues
    );
    return clubIdsAndStatusValues;
  }

  useEffect(() => {
    if (parentClubRelationships && childClubRelationships) {
      const clubStatusRecord: Record<string, ClubStatus> = getClubIdsAndStatus(
        parentClubRelationships,
        childClubRelationships
      );
      setClubRelationships(
        childClubRelationships,
        parentClubRelationships,
        clubStatusRecord
      );
    }
  }, [parentClubRelationships, childClubRelationships]);

  if (!parentClubRelationships || !childClubRelationships) {
    return <Loading />;
  }

  if (!parentClubRelationships && !childClubRelationships) {
    return null;
  }

  const getClubRelationshipBlock = (
    blockType: string,
    clubRelationships?: ClubRelationships
  ) => (
    <div className="my-4">
      <h5>
        {blockType === PARENT_BLOCK_TYPE
          ? t('club-details.sponsorhips.sponsored-by-title', 'Sponsored by')
          : t('club-details.sponsorhips.sponsored-of-title', 'Sponsor of')}
      </h5>
      {!!clubRelationships?.active.length && (
        <>
          <p className="text-2xs mb-0">
            <b>{t('membership.type.member', 'Active')}</b>
          </p>
          <div
            className={classNames(
              { 'mb-0': !clubRelationships.terminated.length },
              { 'mb-2': clubRelationships.terminated.length }
            )}
          >
            {clubRelationships.active.map(
              (
                {
                  id,
                  type,
                  clubName,
                  parentClubId,
                  childClubId,
                  parentDisId,
                  childDisId,
                },
                index
              ) => {
                const isLastIndex = index > clubRelationships.active.length - 1;

                const key = `${blockType}-club-active-link-${id}-${index}`;

                return (
                  <React.Fragment key={`${key}-fragment`}>
                    <Link
                      to={`/domui/club/${
                        isDominoFeature
                          ? parentDisId || childDisId
                          : parentClubId || childClubId
                      }/details`}
                      key={key}
                      className="underline hover:no-underline"
                    >
                      {getClubNameAndType(clubName, type)}
                    </Link>
                    {!isLastIndex && <br />}
                  </React.Fragment>
                );
              }
            )}
          </div>
        </>
      )}
      {!!clubRelationships?.terminated.length && (
        <>
          <p className="text-2xs mb-0">
            <b>{t('membership.terminated', 'Terminated')}</b>
          </p>
          {clubRelationships.terminated.map(({ type, clubName, id }, index) => {
            const isLastIndex = index > clubRelationships.terminated.length - 1;
            const key = `${blockType}-club-terminated-${id}-${index}`;
            return (
              <React.Fragment key={`${key}-fragment`}>
                <>{getClubNameAndType(clubName, type)}</>
                {!isLastIndex && <br />}
              </React.Fragment>
            );
          })}
        </>
      )}
    </div>
  );

  const isClubRelationshipsEmpty = (clubRelationships?: ClubRelationships) =>
    clubRelationships &&
    !clubRelationships[STATUS_ACTIVE_KEY].length &&
    !clubRelationships[STATUS_TERMINATED_KEY].length;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <h2>{t('club-details.sponsorships.title', 'Sponsorhips')}</h2>
        {!isRotaractClubType && (
          <span className="h5 alternate mb-0 text-gray-300">
            {t('club-details.rotary-club.not-editable', 'Not Editable')}
          </span>
        )}
      </div>
      {!isClubRelationshipsEmpty(parentRelationships) &&
        getClubRelationshipBlock(PARENT_BLOCK_TYPE, parentRelationships)}
      {!isClubRelationshipsEmpty(childRelationships) &&
        getClubRelationshipBlock(CHILD_BLOCK_TYPE, childRelationships)}
    </div>
  );
};

export default Sponsorship;
