/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import { isEmpty, values as objectValues } from 'lodash';

import Divider from '@components/Divider';
import TextField from '@components/Formik/PasswordField';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import ClubCTAButtons from '@presenters/web/components/ClubsSaveChangesButtons';

import { processContactInfoForDomino } from './utils';
import editClubContactValidationSchema from './validation';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';
import { getStorageID } from '@use-cases/storage';

import { localizedNavigate } from '@utils/localized-navigate';

import { getBackButtonLabel, getClubDetailsPath } from '@domui-use-cases/clubs';
import AddressFields from 'src/domui/components/Formik/Address';
import PhoneInput from 'src/domui/components/Formik/PhoneInput';
import LanguageSelect from 'src/domui/components/Formik/Select/LanguageSelect';
import mapContactsValuesToFormInput from 'src/domui/domain/clubs/mappers/mapContactsValuesToFormInput';
import {
  Email,
  FormEmailItem,
  FormPhoneItem,
} from 'src/domui/domain/clubs/types';
import { DynamicData } from 'src/domui/domain/type';
import { useAppConfig } from 'src/domui/hooks/appConfig';
import { useFetchLanguages } from 'src/domui/hooks/useFetchLanguages';
import { useFetchOrganization } from 'src/domui/hooks/useFetchOrganization';
import { useUpdateClubContactInfo } from 'src/domui/hooks/useUpdateClubContactInfo';
import Metadata from 'src/domui/utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

import { EmailType } from '@typings/graphql';

const EditClubContact: React.FC<RouteComponentProps & {
  clubId: string;
}> = ({ clubId }) => {
  const { t } = useTranslation();

  const [updateData, setUpdateData] = useState<DynamicData>();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const clubDetailsPath = getClubDetailsPath(clubId);

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryData(response?.countries);
    }
    fetchCountryData();
  }, []);

  const {
    data: languages,
    loading: languagesLoading,
    error: languagesError,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);
  useErrorHandling(
    languagesError?.message,
    !!languagesError,
    'fetch-languages.error'
  );
  const {
    data: getOrganizationData,
    loading: getOrganizationLoading,
    fetchOrganization,
  } = useFetchOrganization();

  useEffect(() => {
    if (clubId) {
      fetchOrganization(clubId);
    }
  }, [clubId]);

  const {
    data: newData,
    loading: updatedLoading,
    error: updatedError,
    updateClubContactInfo,
  } = useUpdateClubContactInfo();

  useErrorHandling(
    updatedError?.description
      ? updatedError.description
      : t('update-contactInfo.error', 'An error occurred.'),
    !!updatedError,
    'update-contactInfo.error'
  );

  useEffect(() => {
    if (updateData) {
      updateClubContactInfo(clubId, updateData, clubDetailsPath);
    }
  }, [updateData]);

  const clearButtonStyle: string = 'absolute -mt-12 -ml-16';

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  if (getOrganizationLoading || !getOrganizationData) {
    return <Loading />;
  }

  if (updatedLoading && updateData) {
    return <Loading />;
  }

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  const formValues = mapContactsValuesToFormInput(getOrganizationData);

  if (!formValues?.primaryEmail?.length) {
    formValues.primaryEmail = JSON.stringify({
      address: '',
      isPrimary: true,
      type: EmailType.Personal,
      id: '',
      nfKey: '',
    });
  }

  const handleFormSubmitForDomino = async (values: DynamicData) => {
    // Add primaryEmailID to primaryEmail

    const primaryEmailWithID = {
      id: String(values?.primaryEmailID),
      email: String(values?.primaryEmail),
      isPrimary: Boolean(values?.primaryEmailIsPrimary),
      type: EmailType.Business.toLocaleLowerCase,
    };

    // Add primaryWebsiteID to primaryWebsite
    const primaryWebsiteWithID = {
      id: values?.primaryWebsiteID,
      primaryWebsite: values?.primaryWebsite,
      otherWebsites: values?.websites.otherWebsites,
      action: '',
    };

    // Ensuring values is not null or undefined before accessing its properties
    const updatedPhonesWithisPrimary: FormPhoneItem[] = values
      ? [
          ...(Array.isArray(values.primaryPhone)
            ? values.primaryPhone
            : [values.primaryPhone ?? []]),
          ...(Array.isArray(values.primaryFax)
            ? values.primaryFax
            : [values.primaryFax ?? []]),
        ].filter(item => item !== undefined && item !== null)
      : [];

    // Filter primaryEmail to include only those with isPrimary set to true
    const primaryEmail = formValues.emails.filter(emails => emails.isPrimary);
    const updatedEmails = processContactInfoForDomino(primaryEmail, [
      primaryEmailWithID,
    ]);
    // Filter address to include only those with isPrimary set to true
    const primaryAddress = formValues.addresses.filter(
      addresses => addresses.isPrimary
    );
    const updatedAddress = processContactInfoForDomino(primaryAddress, [
      values?.address,
    ]);

    const updatedPhones = processContactInfoForDomino(
      formValues.phones,
      updatedPhonesWithisPrimary
    );

    const websitesArray = Array.isArray(formValues.websites)
      ? formValues.websites
      : [formValues.websites];
    const updatedWebsite = processContactInfoForDomino(websitesArray, [
      primaryWebsiteWithID,
    ]);

    const officialLanguagesArray = Array.isArray(formValues.officialLanguages)
      ? formValues.officialLanguages
      : [formValues.officialLanguages];
    const updatedLanguage = processContactInfoForDomino(
      officialLanguagesArray,
      [values?.officialLanguage]
    );

    const contactInfo = {
      contactInfo: {
        addresses: [...updatedAddress],
        emails: [...updatedEmails],
        phones: [...updatedPhones],
        websites: [...updatedWebsite],
        officialLanguage: [...updatedLanguage],
      },
    };
    setUpdateData(contactInfo);

    const userCacheId = `user-account-${clubId}`;
    const previousData = sessionStorage.getItem(
      getStorageID(userCacheId)
    ) as string;
    const prevData = JSON.parse(previousData);

    const newData = {
      getOrganization: {
        ...prevData?.getOrganization,
        email: updatedEmails,
        primaryPhone: updatedPhones,

        primaryAddress: updatedAddress,
      },
    };
    sessionStorage.setItem(getStorageID(userCacheId), JSON.stringify(newData));
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        showModal={modalBackHandler}
        label={getBackButtonLabel(t)}
        classes="mt-4"
      />

      <h2 className="my-3">
        {t('club-editcontact.title', 'Edit Contact Information')}
      </h2>
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmitForDomino}
        validationSchema={editClubContactValidationSchema(t)}
      >
        {({ values, isSubmitting, isValid, dirty }) => {
          const isPrimaryPhoneRequired = !!values.primaryPhone?.number;
          const isPrimaryFaxRequired = !!values.primaryFax?.number;

          const isPrimaryPhoneClearButtonDisabled = objectValues(
            values.primaryPhone
          ).every(isEmpty);
          const isPrimaryFaxClearButtonDisabled = objectValues(
            values.primaryFax
          ).every(isEmpty);
          const isAddressClearButtonDisabled = objectValues(
            values.address
          ).every(isEmpty);

          const primaryPhoneLabels = {
            phoneCode: t(
              'club-editcontact.phone-label-extension',
              'Phone Extension'
            ),
            phoneExtension: t('form.address.phone-extension', 'Extension'),
            phoneNumber: t('club-editcontact.phone-label', 'Phone'),
          };
          const primaryFaxLabels = {
            phoneCode: t(
              'club-editcontact.fax-label-extension',
              'Fax Extension'
            ),
            phoneExtension: t('form.address.phone-extension', 'Extension'),
            phoneNumber: t('club-editcontact.fax-label', 'Fax'),
          };

          return (
            <Form className="max-w-lg mt-6">
              <h2 className="mt-2 desktop:mt-6">
                {t('contact.us.form.email-label', 'Email')}
              </h2>

              <TextField
                name="primaryEmail"
                label={t('club-editcontact.email-label', 'Email')}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('edit-personal-details.subheader-phone', 'Phone')}
              </h2>
              <PhoneInput
                suffix="primaryPhone"
                labels={primaryPhoneLabels}
                isCountryFlagRequired={isPrimaryPhoneRequired}
                displayClearButton
                isClearButtonDisabled={isPrimaryPhoneClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('club-editcontact.fax', 'Fax')}
              </h2>
              <PhoneInput
                suffix="primaryFax"
                labels={primaryFaxLabels}
                isCountryFlagRequired={isPrimaryFaxRequired}
                displayClearButton
                isClearButtonDisabled={isPrimaryFaxClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('club-editcontact.website-label', 'Website')}
              </h2>
              <TextField
                name="primaryWebsite"
                label={t('club-editcontact.website-label', 'Website')}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('form.address.address-label', 'Address')}
              </h2>
              <AddressFields
                countryData={countryData}
                countryId={values.address?.country}
                mainLabel={t(
                  'club-editcontact.address-label',
                  'Mailing address'
                )}
                name={{ hasStates: 'address.hasStates' }}
                displayClearButton
                isClearButtonDisabled={isAddressClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">{t('language', 'Language')}</h2>
              <LanguageSelect
                name="officialLanguage.languageId"
                label={t('club-editcontact.languages-label', 'Rotary language')}
                languages={languages}
                languagesLoading={languagesLoading}
                required
                disabled
              />
              <ClubCTAButtons
                isSubmitting={isSubmitting}
                isValid={isValid && dirty}
                onCancelClick={modalBackHandler}
              />
            </Form>
          );
        }}
      </Formik>
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </div>
  );
};

export default EditClubContact;
