import { DynamicData } from '@domui-domain/type';

import { Maybe } from '@typings/graphql';

export const getDisplayNameById = (
  displayNames: DynamicData,
  displayCode?: Maybe<string>
) =>
  displayNames?.find(({ id }: { id: string }) => displayCode === id)
    ?.languageName || '';
