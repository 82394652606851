/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

// import DeleteConfirmationModal from '@components/Modals/DeleteConfirmationModal';

import ContactInformation from './Contacts/View';
import GeneralInfo from './GeneralInfo/index';
import Meetings from './Meetings/MeetingsList';
import Sponsorships from './Sponsorships';
// import GeneralInfo from './GeneralInfo/index';
// import ClubManagementSystems from './ManagementSystems/View';
// import Meetings from './Meetings/MeetingsList';
// import ClubOfficers from './Officers';

// import { mapMeetingValues } from '@domain/clubs';

import {
  // getOfficersPagePath,
  // getVendorsList,
  isClubRotaract,
} from '@use-cases/clubs';
import { isClubRotaractSatellite } from '@use-cases/clubs/helpers/isClubRotaractSatellite';
// import { useNotifications } from '@use-cases/notifications';

// import { useUserAccount } from '@repositories/auth/hooks';
// import {
//   useDeleteClubMeeting,
//   useFetchClubDetails,
//   useUpdateClubMeeting,
// } from '@repositories/clubs';
// import { useDISCountries } from '@repositories/disCountry';

import { isEditLevel } from '@utils/access-helpers';
// import { FEATURE_DOMINO_CDM_PI1, isEnabled } from '@utils/features';
// import { getClubDestinationURL } from '@utils/getClubDestination';
// import { isRotaryClub } from '@utils/isRotaryClub';
// import { isActiveLeadership, LeadershipDate } from '@utils/leadershipUtils';
// import { localizedNavigate } from '@utils/localized-navigate';
// import { getCurrentTermStartYear } from '@utils/officers';
// import { closeChannel, getChannel } from '@utils/sendMessage';

// import { useAppConfig } from '@hooks/appConfig';
import Divider from '@domui-components/Divider';
import { useFetchOrganization } from '@domui-hooks/useFetchOrganization';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const ClubDetails: React.FC<RouteComponentProps & {
  clubId: string;
  domOrgId: string;
}> = ({ clubId, domOrgId }) => {
  const { t } = useTranslation();
  // const { user } = useAppConfig();
  const { prefix, suffix } = titleTemplateParams(t);
  // const { addInfo } = useNotifications();
  // const addInfoRef = useRef(addInfo);
  // const isDominoFeature = isEnabled(FEATURE_DOMINO_CDM_PI1);
  // const individualIdForFetch =
  //   user?.isLoggedIn && user?.individualId ? user.individualId : null;

  // const { data: userData } = useUserAccount(individualIdForFetch);

  // const [updateMeetings] = useUpdateClubMeeting();
  // const [deleteMeeting] = useDeleteClubMeeting();
  // const { data, loading, error } = useFetchClubDetails(
  //   clubId,
  //   getCurrentTermStartYear(),
  //   dominoId
  // );
  const {
    data: getOrganizationData,
    loading: getOrganizationLoading,
    // error: getOrganizationError,
    fetchOrganization,
  } = useFetchOrganization();

  useEffect(() => {
    if (domOrgId) {
      fetchOrganization(domOrgId);
    }
  }, [domOrgId]);

  // useEffect(() => {
  //   const meetingChannel = getChannel('meeting');
  //   meetingChannel.onmessage = event => {
  //     addInfoRef.current(event?.data, { id: 'club' });
  //   };
  //   const contactChannel = getChannel('contact');
  //   contactChannel.onmessage = event => {
  //     addInfoRef.current(event?.data, { id: 'club' });
  //   };
  //   // Close broadcast channel when the component unmounts
  //   return () => {
  //     closeChannel('meeting');
  //     closeChannel('contact');
  //   };
  // }, []);

  // const { data: countriesList, loading: loadingCountries } = useDISCountries();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [meetingToDelete, setMeetingToDelete] = useState<number | null>(null);

  // if (loading || loadingCountries || error || !data?.club) {
  //   return <Loading />;
  // }
  if (!getOrganizationData || getOrganizationLoading) {
    return <Loading />;
  }

  const isRotaractClub = isClubRotaract('Rotary_Club');
  const isRotaractClubType =
    isRotaractClub || isClubRotaractSatellite('Rotary_Club');

  if (!getOrganizationData?.riClubId) {
    return <NotFound />;
  }

  // const clubSponsorOf = getOrganizationData?.clubSponsorOf.map((item: any) => {
  //   if (item.status === null) {
  //     return { ...item, status: 'Active' };
  //   }
  //   return item;
  // });

  // const clubSponsoredBy = getOrganizationData?.clubSponsoredBy.map(
  //   (item: any) => {
  //     if (item.status === null) {
  //       return { ...item, status: 'Active' };
  //     }
  //     return item;
  //   }
  // );

  const {
    riClubId,
    district,
    charteredDate,
    type,
    addresses,
    meetings,
    clubSponsorOf,
    clubSponsoredBy,
    // district,
    // districtId,
    // physicalLocation,
    // mailingAddress,
    emails,
    phones,
    // primaryFax,
    websites,
    officialLanguage,
    // leaders,
    // childClubRelationships,
    // parentClubRelationships,
    // operationsAccess,
    // vendors,
  } = getOrganizationData;

  // const isUserHomeDistrict = userData?.getIndividual.clubAffiliations.some(
  //   affiliation => district === affiliation.district?.riDistrictId.toString()
  // );

  // const officersWithActiveRole = leaders?.filter(({ startDate, endDate }) =>
  //   isActiveLeadership({
  //     startDate,
  //     endDate,
  //   } as LeadershipDate)
  // );

  // const isRotaryClubType = isRotaryClub(clubType);

  // const canEditClubInfo = 'Viewer';
  // const canEditOfficers = isEditLevel(operationsAccess.leaderships);

  // const handleDeleteMeeting = async (meetingIndex: number | null) => {
  //   if (meetingIndex != null) {
  //     if (isDominoFeature) {
  //       const meeting = meetings[Number(meetingIndex)];
  //       const selectedMeeting = {
  //         clubId,
  //         dominoId,
  //         meetingId: meeting.meetingId as string,
  //       };
  //       await deleteMeeting({
  //         variables: selectedMeeting,
  //       });
  //     } else {
  //       const newMeetings = [
  //         ...meetings.slice(0, meetingIndex),
  //         ...meetings.slice(meetingIndex + 1),
  //       ].map(mapMeetingValues);
  //       await updateMeetings({
  //         variables: { clubId, meetings: newMeetings },
  //       });
  //     }
  //   }
  // };

  // const handleGoToOfficers = () =>
  //   localizedNavigate(getOfficersPagePath(clubId), {
  //     state: {
  //       isFromDetailsPage: true,
  //     },
  //   });

  // const isClubManagementSystemsSectionShown =
  //   user?.isLoggedIn &&
  //   (!!vendors.length || (!vendors.length && canEditClubInfo));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function physicalLocation(addresses: any) {
    return addresses.find(
      (address: { type: string }) => address.type === 'Physical Location'
    );
  }

  // const clubSponsorOf = [
  //   {
  //     startDate: '2024-10-01',
  //     endDate: null,
  //     id: '0c397601-98a4-44ad-a375-d4774ddd148c',
  //     childClubId: 'cc236516-3d33-4a9d-85b6-f2eb23680d1e',
  //     childDisId: 'a321bca5-590d-4a0c-a8e6-aa7a1d8d79f4',
  //     nfKey: null,
  //     type: 'RotaryClub',
  //     clubName: 'San Marco-Jacksonville',
  //     status: 'Active',
  //   },
  //   {
  //     startDate: '1968-12-18',
  //     endDate: null,
  //     id: '7c25ecc2-c158-47aa-9837-5ff14c177af9',
  //     childClubId: '6988e1c8-76e4-477b-a6f5-afaef773d979',
  //     childDisId: null,
  //     nfKey: '4aa8f113-3e6e-41aa-829e-53d280b12bf4',
  //     type: 'RotaryClub',
  //     clubName: 'Bishop Kenny High School',
  //     status: 'Active',
  //   },
  //   {
  //     startDate: '1998-05-26',
  //     endDate: null,
  //     id: 'c1a734f6-1e28-4db7-9e65-f41ac5fcf1f0',
  //     childClubId: '33e1b6ed-9367-4915-a413-114c7a48e565',
  //     childDisId: '9dd87550-93d5-4bd8-875b-96de22367f2d',
  //     nfKey: 'bf445a76-a512-44ff-a44d-7ac6eb26551f',
  //     type: 'RotaractClub',
  //     clubName: "Florida's First Coast",
  //     status: 'Active',
  //   },
  //   {
  //     startDate: '2021-08-05',
  //     endDate: null,
  //     id: '1e1ed557-757b-40fc-bce4-2c65a6bc8374',
  //     childClubId: '3e196066-31e6-48a5-b393-1f8c5999d213',
  //     childDisId: '6a27fa05-0118-49ad-8296-20bd7fc44d58',
  //     nfKey: '7107f233-22bc-4f6d-82aa-8afbc373c8f6',
  //     type: 'RotaryClub',
  //     clubName: 'Northwest Jacksonville',
  //     status: 'Active',
  //   },
  //   {
  //     startDate: '2013-02-14',
  //     endDate: null,
  //     id: 'b82ad460-c42c-42b6-9541-84d32c5559cb',
  //     childClubId: '1755c178-4a8c-42d8-a0ab-d98a0dd15faf',
  //     childDisId: null,
  //     nfKey: 'c337bf6b-8698-48b7-9118-07acf645acbc',
  //     type: 'RotaryClub',
  //     clubName: 'Wolfson High School',
  //     status: 'Active',
  //   },
  //   {
  //     startDate: '2007-04-09',
  //     endDate: null,
  //     id: 'e130b3d6-0e53-425d-a0ee-3a34564e67a8',
  //     childClubId: '5fb45a1d-f601-4bde-bcbb-51fbcc1929da',
  //     childDisId: null,
  //     nfKey: '7ddcf4f5-3da0-4aca-a010-a3d56c9a90eb',
  //     type: 'RotaryClub',
  //     clubName: 'Stanton College Preparatory',
  //     status: 'Active',
  //   },
  // ];
  // const clubSponsoredBy = [
  //   {
  //     startDate: '1955-01-27',
  //     endDate: null,
  //     id: '0caa15a6-3604-42ec-ad5e-9ea0137d0b51',
  //     parentClubId: '2c0b9dcc-f01a-45d5-aa65-ba42ee81cd1b',
  //     parentDisId: '5dbc86dc-b3e9-4130-a990-faba678579f4',
  //     nfKey: '63bc6127-1fd2-4b89-b640-65e8662d78bb',
  //     type: 'RotaryClub',
  //     clubName: 'Jacksonville',
  //     status: 'Active',
  //   },
  // ];

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.club-details-section',
          '{{prefix}} Club details {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="flex flex-col mt-8 desktop:flex-row">
        <div className="desktop:w-3/5 desktop:px-6 desktop:mr-15 w-full">
          <GeneralInfo
            // eslint-disable-next-line react/jsx-boolean-value
            isUserHomeDistrict={true}
            districtId={district?.districtDisId}
            district={district?.riDistrictId}
            physicalLocation={physicalLocation(addresses)}
            charteredDate={charteredDate}
            riClubId={riClubId}
            clubType={type}
          />
          <Divider />
          <ContactInformation
            emails={getOrganizationData?.emails || []}
            phones={getOrganizationData?.phones || []}
            // primaryFax={primaryFax}
            websites={getOrganizationData?.websites || null}
            officialLanguage={getOrganizationData?.officialLanguage || null}
            addresses={getOrganizationData?.addresses || []}
            isEditable
            // isEditable={canEditClubInfo}
            //  isUserLoggedIn={user?.isLoggedIn}
            editContactInformationLink={`/domui/club/${clubId}/edit-contact`}
            // countries={countriesList?.countries || []}
            //  individualOperationAccess={data.club.operationsAccess.club}
          />

          {!!(clubSponsoredBy?.length || clubSponsorOf?.length) && (
            <>
              <Divider />
              <Sponsorships
                parentClubRelationships={clubSponsoredBy}
                childClubRelationships={clubSponsorOf}
                isRotaractClubType={isRotaractClubType}
              />
            </>
          )}
          {/* 
          {(isRotaractClub || isRotaryClubType) && (
            <>
              {!!officersWithActiveRole?.length && (
                <>
                  <Divider />
                  <ClubOfficers
                    leaders={officersWithActiveRole}
                    isEditable={canEditOfficers}
                    handleGoToOfficers={handleGoToOfficers}
                    profilePreviousLinkPath={getClubDestinationURL(
                      clubId,
                      'details'
                    )}
                  />
                </>
              )}
              {isClubManagementSystemsSectionShown && (
                <>
                  <Divider />
                  <ClubManagementSystems
                    isEditable={canEditClubInfo}
                    vendors={getVendorsList(vendors)}
                    editManagementSystemsLink={`/club/${clubId}/edit-management-systems`}
                  />
                </>
              )}
            </>
          )} */}
        </div>
        <div className="desktop:w-2/5 w-full">
          <Meetings
            editMeetingLink={(index: number) =>
              `/domui/club/${clubId}/edit-meeting/${index}`
            }
            isEditable
            addMeetingLink={`/domui/club/${clubId}/add-meeting`}
            handleDelete={meetingIndex => setMeetingToDelete(meetingIndex)}
            meetings={meetings || []}
          />
        </div>
      </div>
      {/* <DeleteConfirmationModal
        isOpen={meetingToDelete !== null}
        onConfirm={() => {
          handleDeleteMeeting(meetingToDelete);
          setMeetingToDelete(null);
        }}
        closeModal={() => setMeetingToDelete(null)}
        title={t(
          'club-details.meetings.confirm-deletion.title',
          'Are you sure you want to delete this meeting?'
        )}
      /> */}
    </>
  );
};

export default ClubDetails;
