import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Organization from '@domui-utils/workflow/organization';

// Custom hook to handle API calls
export const useFetchOrganization = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchOrganization = async (domOrgId: string) => {
    // Clear error before new request
    try {
      if (domOrgId) {
        setLoading(true);
        setError(null);
        const wfService = new Organization();
        const response = await wfService.getOrganization(domOrgId);
        setData(response);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchOrganization };
};
