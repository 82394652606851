/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

interface Email {
  email: string;
  id: string;
  isPrimary: boolean;
  nfKey: string;
  type: string;
}

interface Phone {
  id: string;
  type: string;
  number: string;
  areaCode: string;
  countryId: string;
  country: string;
  extension: string | number;
  isPrimary: boolean;
  isFax: boolean;
  nfKey: string;
}

interface Address {
  id: string;
  nfKey: string;
  type: string;
  lineOne: string;
  lineTwo: string;
  lineThree: string;
  countryId: string;
  city: string;
  stateId: string;
  internationalProvince: string;
  areaCode: string;
  isPrimary: boolean;
}

interface Website {
  id: string;
  primaryWebsite: string;
  otherWebsites: string;
}

interface OfficialLanguage {
  id: string;
  nfKey: string;
  languageId: string;
}

export type ContactInformationFormValues = {
  address: {
    countryId: string;
    city: string;
    postalCode: string;
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    state: string;
    stateId: string;
    internationalProvince: string;
    hasStates: boolean;
    id: string;
    country: string;
    isPrimary: boolean;
    type: string;
  };
  primaryEmail: string;
  primaryWebsite: string;
  primaryPhone: {
    number: string;
    countryId: string;
    extension: string | number;
    country: string;
    id: string | null;
    isPrimary: boolean;
    isFax: boolean;
  };

  primaryFax: {
    number: string;
    countryId: string;
    extension: string | number;
    country: string;
    id: string | null;
    isPrimary: boolean;
    isFax: boolean;
  };
  officialLanguage: {
    languageId: string;
    id: string | null;
  };
  primaryEmailID: string | null;
  primaryWebsiteID: string | null;
  primaryEmailType: string | null;
  primaryEmailIsPrimary: boolean;
  otherWebsite: string | null;
  addresses: Address[];
  emails: Email[];
  phones: Phone[];
  websites: Website[];
  officialLanguages: OfficialLanguage[];
};

export default (input: any): ContactInformationFormValues => {
  const primaryAddress = input.addresses.find(
    (address: Address) => address.isPrimary
  );
  const primaryEmail = input.emails.find((email: Email) => email.isPrimary);

  const primaryPhone =
    input.phones && input.phones.length > 0
      ? input.phones.find((phone: Phone) => phone.isPrimary && !phone.isFax) ||
        input.phones.find((phone: Phone) => phone.isFax) ||
        input.phones
      : '';

  const primaryFax =
    input.phones && input.phones.length > 0
      ? input.phones.find((phone: Phone) => phone.isFax && phone.isPrimary) ||
        input.phones.find((phone: Phone) => phone.isFax) ||
        input.phones
      : '';

  return {
    address: {
      countryId: primaryAddress?.country || '',
      city: primaryAddress?.city || '',
      postalCode: primaryAddress?.postalCode || '',
      lineOne: primaryAddress?.lineOne || '',
      lineTwo: primaryAddress?.lineTwo || '',
      lineThree: primaryAddress?.lineThree || '',
      state: primaryAddress?.state || '',
      stateId: primaryAddress?.stateId || '',
      internationalProvince: primaryAddress?.internationalProvince || '',
      hasStates: primaryAddress?.hasStates || false,
      id: primaryAddress?.id || null,
      country: primaryAddress?.country || '',
      isPrimary: primaryAddress?.isPrimary || '',
      type: primaryAddress?.type || '',
    },
    primaryEmail: primaryEmail?.email || '',
    primaryWebsite: input.websites.primaryWebsite || '',
    primaryPhone: {
      number: primaryPhone?.number || '',
      countryId: primaryPhone?.countryId || '',
      extension: primaryPhone?.extension || '',
      country: primaryPhone?.country || '',
      id: primaryPhone?.id || null,
      isPrimary: primaryPhone?.isPrimary || null,
      isFax: primaryPhone?.isFax,
    },
    primaryFax: {
      number: primaryFax?.number || '',
      countryId: primaryFax?.countryId || '',
      extension: primaryFax?.extension || '',
      country: primaryFax?.country || '',
      id: primaryFax?.id || null,
      isPrimary: primaryFax?.isPrimary || null,
      isFax: primaryFax?.isFax || null,
    },
    officialLanguage: {
      languageId: input.officialLanguage.languageId || '',
      id: input.officialLanguage?.id || null,
    },
    primaryWebsiteID: input.websites?.id || null,
    primaryEmailID: primaryEmail?.id || null,
    primaryEmailType: primaryEmail.type || null,
    primaryEmailIsPrimary: primaryEmail.isPrimary || null,
    otherWebsite: input.websites?.otherWebsite || null,
    emails: input.emails,
    addresses: input.addresses,
    phones: input.phones,
    websites: input.websites,
    officialLanguages: input.officialLanguage,
  };
};
