/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';
import { MeetingFormValues } from '@presenters/web/components/ClubsMeetingForm/types';

import { CREATE_CLUB_MEETING_INITIAL_VALUES } from '@domain/clubs/constants';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { FEATURE_DOMINO_CDM_PI1, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';

import { mapMeetingInputToValues } from '@domui-domain/clubs';
import { useAddMeeting } from '@domui-hooks/useAddMeeting';
import MeetingForm from '@domui-presenters/web/components/ClubsMeetingForm';
import { getBackButtonLabel, getClubDetailsPath } from '@domui-use-cases/clubs';
import { useFetchCountries } from 'src/domui/hooks/useFetchCountries';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
}

const AddMeeting: React.FC<Props> = ({ clubId }) => {
  const { t, i18n } = useTranslation();
  const { addError } = useNotifications();
  const [meetingInfo, setMeetingInfo] = useState(null);

  // const { data, error, loading } = useFetchClubMeeting(clubId, dominoId);
  // const [updateMeeting, { loading: isCreating }] = useUpdateClubMeeting();
  // const [
  //   addClubMeeting,
  //   { loading: isAddMeetingCreating },
  // ] = useAddClubMeeting();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const isFeatureDomino = isEnabled(FEATURE_DOMINO_CDM_PI1);
  const clubDetailsPath = getClubDetailsPath(clubId);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  // const { meetings } = data.clubById;
  // const { countries } = data;
  const {
    data: countries,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: countriesLoading,
    // error: getOrganizationError,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: updatedMeetingData,
    loading: updatedMeetingLoading,
    error: updatedMeetingError,
    addMeeting,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  } = useAddMeeting();
  // if (updatedMeetingData) {
  //   console.log('success');
  //   addSuccess(
  //     t('club-addmeeting.success', 'Success! The meeting has been added')
  //   );
  //   getChannel('meeting').postMessage(
  //     t(
  //       'club-meeting-tab-update.message',
  //       'Please refresh this browser tab to see updated information.'
  //     )
  //   );
  //   localizedNavigate(clubDetailsPath);
  // }

  useErrorHandling(
    updatedMeetingError?.description
      ? updatedMeetingError.description
      : t('add-meeting.error', 'An error occurred.'),
    !!updatedMeetingError,
    'add-meeting.error'
  );

  useEffect(() => {
    if (meetingInfo) {
      addMeeting(clubId, meetingInfo, clubDetailsPath);
    }
  }, [meetingInfo]);

  if (updatedMeetingLoading) {
    return <Loading />;
  }

  const handleSubmit = async (values: MeetingFormValues) => {
    try {
      if (isFeatureDomino) {
        // const mutValues = {
        //   clubId,
        //   dominoId,
        //   meeting: {
        //     ...mapMeetingInputToValues(i18n.language, values),
        //   },
        // };
        // await addClubMeeting({ variables: mutValues });
      } else {
        const countryValue: any = countries?.find(
          (c: any) => c.countryName === values.countryId
        );
        // eslint-disable-next-line no-param-reassign
        const mutValues: any = {
          meeting:
            //  ...meetings.map(mapMeetingValues),
            {
              ...mapMeetingInputToValues(i18n.language, countryValue, values),
              meetingId: null,
            },
        };
        setMeetingInfo(mutValues);
        // await updateMeeting({ variables: mutValues });
      }
    } catch (error) {
      addError((error as Error).message);
    }
  };

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        showModal={modalBackHandler}
        label={getBackButtonLabel(t)}
      />
      <Title>{t('club-addmeeting.title', 'Add New Meeting')}</Title>
      <MeetingForm
        initialValues={{ ...CREATE_CLUB_MEETING_INITIAL_VALUES }}
        countries={countries}
        handleSubmit={handleSubmit}
        onCancelClick={modalBackHandler}
      />
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </div>
  );
};

export default AddMeeting;
