import React from 'react';

import Link from '@components/Link';
import LinkEdit from '@components/LinkEdit';
import HarrisButton from '@presenters/web/components/Leads/HarrisButton';

import ContactInformationPhone from '@domui-presenters/web/pages/Profile/View/ContactInformation/ContactInformationPhone';

import { useTranslation } from '@external/react-i18next';

enum EmailType {
  Business = 'Business',
  Personal = 'Personal',
}

enum PhoneType {
  Business = 'Business',
  Home = 'Home',
  Mobile = 'Mobile',
}

enum AddressType {
  Business = 'Business',
  Home = 'Home',
  HostCountry = 'HostCountry',
  Localized = 'Localized',
  Project = 'Project',
}

type ContactInfoProps = {
  emails?:
    | Array<{
        type: EmailType;
        email: string;
        isPrimary: boolean;
      }>
    | null
    | undefined;
  phones?:
    | Array<{
        country: string;
        countryId: string;
        isPrimary: boolean;
        extension?: string | null | undefined;
        number: string;
        isFax: boolean;
        type?: PhoneType | null | undefined;
      }>
    | null
    | undefined;
  addresses?:
    | Array<{
        type: AddressType;
        isPrimary: boolean;
        lineOne?: string | null | undefined;
        lineTwo?: string | null | undefined;
        lineThree?: string | null | undefined;
        country?: string | null | undefined;
        countryId: string;
        city?: string | null | undefined;
        stateId?: string | null | undefined;
        state?: string | null | undefined;
        postalCode?: string | null | undefined;
      }>
    | null
    | undefined;

  websites?:
    | {
        primaryWebsite: string | null;
        otherWebsites: Array<string> | null;
      }
    | null
    | undefined;

  officialLanguage?:
    | {
        language?: string | null | undefined;
        languageId?: string | null | undefined;
      }
    | null
    | undefined;

  isEditable: boolean;
  editContactInformationLink: string;
};

const ContactInformation: React.FC<ContactInfoProps> = ({
  emails,
  phones,
  addresses,
  websites,
  officialLanguage,
  editContactInformationLink,
  isEditable,
}) => {
  const { t } = useTranslation();

  const title = t(
    'club-details.contact-information.title',
    'Contact Information'
  );

  const ensureHttpPrefix = (url: string): string => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2>{title}</h2>
        {isEditable ? (
          <LinkEdit path={editContactInformationLink} component={title} />
        ) : (
          <span className="h5 alternate mb-0 text-gray-300">
            {t('club-details.rotary-club.not-editable', 'Not Editable')}
          </span>
        )}
      </div>

      <div className="flex flex-col">
        {!!emails?.length && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.email-address-label',
                'Email'
              )}
            </h5>
            {emails
              .filter(email => email.isPrimary)
              .map(email => (
                <span key={email.email}>
                  <Link to={`mailto:${email.email}`}>{email.email}</Link>
                </span>
              ))}
          </div>
        )}

        {!!phones?.length && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.phone-number-label',
                'Phone'
              )}
            </h5>
            <span>
              {phones
                .filter(phone => phone.isPrimary)
                .map(phone => (
                  <ContactInformationPhone phone={phone} key={phone.number} />
                ))}
            </span>
          </div>
        )}
        {phones?.length && phones.filter(phone => phone.isFax).length > 0 && (
          <div className="my-4">
            <h5>
              {t('club-details.contact-information.fax-number-label', 'Fax')}
            </h5>
            <span>
              {phones
                .filter(phone => phone.isFax)
                .map(phone => (
                  <div key={`${phone.isFax} `}>
                    <div>{phone?.number}</div>
                  </div>
                ))}
            </span>
          </div>
        )}
        {websites?.primaryWebsite && (
          <div className="my-4">
            <h5>
              {t('club-details.contact-information.website-label', 'Website')}
            </h5>
            <span>
              <Link
                to={ensureHttpPrefix(websites.primaryWebsite || '')}
                target="_blank"
              >
                {websites.primaryWebsite}
              </Link>
            </span>
          </div>
        )}

        {!!officialLanguage && (
          <div className="my-4">
            <h5>
              {t(
                'club-details.contact-information.language-label',
                'Rotary language'
              )}
            </h5>
            <span>{officialLanguage.language}</span>
          </div>
        )}

        {!!addresses?.length && (
          <div className="my-4">
            {addresses
              .filter(address => address.isPrimary)
              .map(address => (
                <div key={`${address.lineOne} ${address.type}`}>
                  <h5>
                    {t(
                      'club-details.contact-information.mailing-address-label',
                      'Mailing address'
                    )}
                  </h5>
                  <div>
                    {address.lineOne && (
                      <span className="block">{address.lineOne}</span>
                    )}
                    {address.lineTwo && (
                      <span className="block">{address.lineTwo}</span>
                    )}
                    {address.lineThree && (
                      <span className="block">{address.lineThree}</span>
                    )}
                    {address.city && <span>{address.city}</span>}
                    {address.postalCode && <span>, {address.postalCode}</span>}
                    {address.country && <span>, {address.country}</span>}
                    {address.state && (
                      <span className="block"> {address.state}</span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
        {/* {joinClubButton && ( */}
        <div className="my-4">
          <HarrisButton variant="secondary">Join this club</HarrisButton>
        </div>
        {/* )} */}

        {/* {isUserLoggedIn && showMailingAddress && ( */}
      </div>
    </div>
  );
};

export default ContactInformation;
