/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import MeetingPreview from '@presenters/web/components/ClubsMeetingForm/MeetingPreview';

import Icon from '@domui-components/Icon';
import LinkEdit from '@domui-components/LinkEdit';

import { useTranslation } from '@external/react-i18next';

type Props = {
  editMeetingLink: string;
  isEditable: boolean;
  index: number;
  meetingsCount: number;
  meeting: any;
  handleDelete: () => void;
};

const MeetingItem: React.FC<Props> = ({
  editMeetingLink,
  meeting,
  index,
  meetingsCount,
  handleDelete,
  isEditable,
}) => {
  const { t } = useTranslation();

  const editLinkComponent =
    meeting?.name || `${t('club-details.meetings.meeting-tag', 'Meeting')}`;

  return (
    <div
      className="border border-gray-300 py-4 px-3 mb-4"
      data-testid="meeting-item"
    >
      {meetingsCount > 1 ? (
        <div className="flex justify-between">
          <h3 className="h5 text-gray-400">
            {`${index + 1}  ${t(
              'club-details.meetings.counter',
              'of'
            )} ${meetingsCount}`}
          </h3>
          <div className="flex">
            {isEditable && (
              <>
                {meetingsCount > 1 && (
                  <div
                    onClick={handleDelete}
                    className="edit-link mr-2 link-styles-off flex items-center inline-block h5 alternate text-bright-blue-600 flex"
                  >
                    <Icon name="ui/trashcan" size="12" className="mr-2" />
                    <span>
                      {t('club-details.meetings.delete-link-label', 'Delete')}
                    </span>
                  </div>
                )}
                <LinkEdit
                  path={editMeetingLink}
                  component={editLinkComponent}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-end">
          {isEditable && (
            <LinkEdit path={editMeetingLink} component={editLinkComponent} />
          )}
        </div>
      )}
      <div className="break-all">
        <MeetingPreview meeting={meeting} />
      </div>
    </div>
  );
};

export default MeetingItem;
