/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

// Components
import Loading from '@components/Loading';

import Select from '../Select';

// Hooks
// import { useTranslation } from '@external/react-i18next';
// import { useDisplayNames } from '@hooks/useDisplayNames';

// Types

import { DisplayNames } from '@typings/operations';

// Constants
// import { DISPLAY_TYPE } from '@domain/common';

const generateOptions = (displayNames: DisplayNames[]) =>
  displayNames?.map(({ languageName, id }: any) => ({
    label: languageName,
    value: id,
  })) || [];

const LanguageSelect: React.FC<any> = ({
  languages,
  languagesLoading,
  ...props
}) => {
  if (languagesLoading || !languages) {
    return <Loading inline small />;
  }

  const languageOptions = generateOptions(languages).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return <Select searchable options={languageOptions} {...props} />;
};

export default LanguageSelect;
