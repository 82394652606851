// Types
import { isEqual } from 'lodash';

import {
  BasicLanguageInput,
  FormAddressItem,
  FormEmailItem,
  FormPhoneItem,
  FormWebsiteItem,
} from 'src/domui/domain/clubs/types';

export const processContactInfoForDomino = (
  formInfo:
    | FormEmailItem[]
    | FormAddressItem[]
    | FormPhoneItem[]
    | FormWebsiteItem[]
    | BasicLanguageInput[],
  submittedInfo:
    | FormEmailItem[]
    | FormAddressItem[]
    | FormPhoneItem[]
    | FormWebsiteItem[]
    | BasicLanguageInput[]
) => {
  const formInfoIdValuePair = new Map(formInfo.map(info => [info.id, info]));

  const formInfoIds = new Set(formInfoIdValuePair.keys());

  const submittedInfoIdValuePair = new Map(
    submittedInfo.map(info => [info.id, info])
  );

  const submittedInfoIds = new Set(submittedInfoIdValuePair.keys());

  // If Submitted Info has missing id's, they are considered deleted and action is "Deleted".
  const deletedInfoIds = Array.from(formInfoIds).filter(info => {
    return !submittedInfoIds.has(info);
  });

  const deletedInfos = deletedInfoIds.map(infoId => {
    const deletedInfo = formInfoIdValuePair.get(infoId);
    delete deletedInfo?.__typename;
    return { ...(deletedInfo || { infoId }), action: 'Delete' };
  });

  const addedUpdatedInfos = submittedInfo.map(
    (
      info:
        | FormEmailItem
        | FormAddressItem
        | FormPhoneItem
        | FormWebsiteItem
        | BasicLanguageInput
    ) => {
      const actionUpdatedInfo = { ...info };
      // If Submitted Info does not have id, its a newly added Info and action is "Add".
      if (!info.id) {
        actionUpdatedInfo.action = 'Add';
      } else {
        const selectedInfo = formInfoIdValuePair.get(info.id);

        if (selectedInfo) {
          // If Form Info values are different from submitted Info values, action is "Update"
          if (!isEqual(info, selectedInfo)) {
            actionUpdatedInfo.action = 'Update';
          }
        }
      }

      delete actionUpdatedInfo.__typename;
      return actionUpdatedInfo;
    }
  );

  return [...addedUpdatedInfos, ...deletedInfos];
};
