/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Utils
import { DISPLAY_TYPE } from '@domain/common';

import { getIntlDisplayName } from '@utils/getIntlDisplayName';

// Types
import { AddressInput, MeetingInput } from '@typings/operations';

import { ClubMeetingValues } from '../types';

// Constants

export default (
  currentLanguage: string,
  countryValue: { id: any },
  {
    weekday,
    type,
    languageId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    countryId,
    city,
    postalCode,
    lineOne,
    lineTwo,
    lineThree,
    internationalProvince,
    stateId,
    ...values
  }: ClubMeetingValues
): MeetingInput => ({
  ...values,
  weekday: weekday!,
  type: type!,
  language: getIntlDisplayName(
    currentLanguage,
    DISPLAY_TYPE.LANGUAGE,
    languageId!
  ),
  languageId,
  address: {
    countryId: countryValue?.id,
    city,
    postalCode,
    lineOne: lineOne || '', // required in input but not query result
    lineTwo,
    lineThree,
    stateId,
    // submitting the international province gives a 500 error on DIS?
    internationalProvince,
  } as AddressInput,
});
